import * as React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { backend_url } from "../../services/Constants";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Input,
  TextField,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Project Name",
  },
  {
    id: "crop_carbon",
    numeric: true,
    disablePadding: false,
    label: "Crop Carbon (MtCO2e)",
  },
  {
    id: "tree_carbon",
    numeric: true,
    disablePadding: false,
    label: "Tree Carbon (MtCO2e)",
  },
  {
    id: "duration",
    numeric: true,
    disablePadding: false,
    label: "Project Duration",
  },
  // {
  //   id: "date",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Date Created",
  // },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, setAPISort, apiSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleSort = (id) => {
    if (apiSort === id) {
      setAPISort(`-${id}`); // Deselect the column
    } else {
      setAPISort(id); // Select the column
    }
  };

  return (
    <TableHead sx={{ backgroundColor: "#29333F" }}>
      <TableRow className="flex rounded-full">
        <TableCell
          sx={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
            fontWeight: "bold",
            fontSize: "15px",
            color: "#fff",
          }}
        >
          S/N
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: "bold", fontSize: "15px", color: "#fff" }}
            onClick={() => headCell.id !== "date" && handleSort(headCell.id)}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: "#fff !important" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ReportPage(props) {
  const { token } = props;
  const [reports, setReports] = useState();
  const [carbonReport, setCarbonReport] = useState();
  // const totalItems = reports !== undefined && reports.count;
  const [searchValue, SetSearchValue] = useState("");
  const [apiSort, setAPISort] = useState("");
  const [carbonMin, SetCarbonMin] = useState(0);
  const [carbonMax, SetCarbonMax] = useState(99999999);
  const [offset, setOffset] = useState(0);
  const limit = 50;

  const reports_data = reports && reports.results ? reports.results : [];
  // const totalPages = Math.ceil(totalItems / 50);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/reports/?` +
          new URLSearchParams({
            offset: offset,
            limit: limit,
            // mrv_p: currentPage,
            name__icontains: searchValue,
            sort: apiSort,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setReports(data));
    }

    fetchData();
  }, [token, searchValue, apiSort, offset, limit]);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/carbon_report/?` +
          new URLSearchParams({
            // mrv_p: currentPage,
            offset: offset,
            limit: limit,
            project_name__icontains: searchValue,
            // carbon__lt: carbonMax,
            // carbon__gt: carbonRange,
            sort: apiSort,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setCarbonReport(data));
    }

    fetchData();
  }, [token, searchValue, apiSort, offset, limit]);

  const calculateCropCarbon = (data) => {
    let totalCarbon = 0;

    data.forEach((crop) => {
      totalCarbon += crop.carbon_sequestration;
    });

    return totalCarbon;
  };

  const calculateTreeCarbon = (data) => {
    let totalCarbon = 0;

    data.forEach((crop) => {
      totalCarbon += crop.carbon;
    });

    return totalCarbon;
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("size");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = reports_data.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setOffset(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reports_data.length) : 0;

  // const visibleRows = useMemo(
  //   () =>
  //     stableSort(reports_data, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  return (
    <Box
      className={
        "flex flex-col flex-auto w-full p-12 shadow rounded-2xl overflow-hidden mt-64"
      }
    >
      <Paper className="flex items-center  space-x-8 px-8 border-1 shadow-0 mb-12 py-4">
        <Input
          placeholder="Search report name"
          className="flex flex-1"
          disableUnderline
          fullWidth
          value={searchValue}
          inputProps={{
            "aria-label": "Search Report Name",
          }}
          onChange={(e) => SetSearchValue(e.target.value)}
        />
        <IconButton>
          <SearchIcon color="primary" />
        </IconButton>
      </Paper>

      <div className="pb-12 w-full">
        <Accordion className="px-8 py-6 rounded-full border-1 shadow-0">
          <AccordionSummary
            expandIcon={<ExpandCircleDownIcon color="primary" />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" component={"p"} className="font-bold">
              Filter Reports By
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex flex-col sm:flex-row">
              <TextField
                sx={{ mt: 2, mr: { sm: 0, lg: 4 } }}
                id="carbon-min"
                label="Carbon Min"
                placeholder="Carbon Min"
                value={carbonMin}
                onChange={(e) => {
                  SetCarbonMin(e.target.value);
                }}
              />

              <TextField
                sx={{ mt: 2, mr: { sm: 0, lg: 4 } }}
                id="carbon-max"
                label="Carbon Max"
                placeholder="Carbon Max"
                value={carbonMax}
                onChange={(e) => {
                  SetCarbonMax(e.target.value);
                }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <Paper className="py-4">
        <div className="flex flex-col sm:flex-row items-start justify-between px-4 pb-12">
          <Typography
            variant="p"
            className="text-md text-center font-semibold  "
          >
            Reports
          </Typography>
        </div>

        {carbonReport && carbonReport.length === 0 && (
          <Typography
            variant="h6"
            component={"p"}
            className="text-center font-bold"
          >
            No reports found.
          </Typography>
        )}

        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            {carbonReport && carbonReport.length > 0 && (
              <EnhancedTableHead
                setAPISort={setAPISort}
                apiSort={apiSort}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={carbonReport.length}
              />
            )}

            <TableBody>
              {carbonReport &&
                carbonReport.length > 0 &&
                carbonReport.map((report, index) => {
                  const isItemSelected = isSelected(report.created_date);
                  return (
                    <TableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      // sx={{ cursor: "pointer" }}
                      sx={{
                        py: 2,
                        // "&:last-child td, &:last-child th": { border: 1 },
                      }}
                      component={Link}
                      to={
                        report.crop[0] !== undefined
                          ? `/report/${report.project}?map=true&season=${report.crop[0].season}`
                          : `/report/${report.project}`
                      }
                    >
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                        }}
                      >
                        {(index += 1)}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                        }}
                        component="th"
                        scope="row"
                        padding="none"
                      >
                        {report.project_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                        }}
                        align="right"
                      >
                        {(calculateCropCarbon(report.crop) * 0.00367).toFixed(
                          2
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                        }}
                        align="right"
                      >
                        {(calculateTreeCarbon(report.trees) * 0.00367).toFixed(
                          2
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                        }}
                        align="right"
                      >
                        {report.start_date} - {report.end_date}
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                        }}
                        align="right"
                      >
                        {moment(report.created_date).format("YYYY-MM-DD")}
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell
                    sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.3)" }}
                    colSpan={6}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[50]}
          component="div"
          count={50}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

import axios from "axios";
import { zowasel_token, zowasel_url } from "../../services/Constants";

export const VerifyFarmerTelephone = async (telephone_no) => {
  try {
    const result = await axios.get(
      `${zowasel_url}/controls/farmers/getbytelephone/0/10/${telephone_no}`
    );
    return result.data.error;
  } catch (err) {
    console.error("Error verifying telephone number:", err);
    return false;
  }
};

export const VerifyBusinessEmail = async (email) => {
  try {
    const result = await axios.get(
      `${zowasel_url}/controls/business/getbyemail/${email}`
    );
    return result.data.message === "Business with this email not found";
  } catch (err) {
    console.error("Error verifying email address:", err);
    return false;
  }
};

export const RegisterFarmerZowasel = async (email, telephone_no) => {
  const farmer = { email, telephone1: telephone_no, channel: "MRV" };
  try {
    const response = await axios.post(
      `${zowasel_url}/controls/farmers/addfarmers`,
      farmer,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${zowasel_token}`,
        },
        withCredentials: true,
      }
    );
    return response;
  } catch (err) {
    console.error("Error registering farmer:", err);
    return null;
  }
};

export const RegisterBusinessZowasel = async (
  email,
  telephone_no,
  username
) => {
  const business = {
    businessemail: email,
    businessmobile: telephone_no,
    businessname: username,
    registeredfrom: "MRV",
  };
  try {
    const response = await axios.post(
      `${zowasel_url}/controls/business/addbusiness`,
      business,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${zowasel_token}`,
        },
        withCredentials: true,
      }
    );
    return response;
  } catch (err) {
    console.error("Error registering farmer:", err);
    return null;
  }
};

import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/Theme";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { CssBaseline, useMediaQuery } from "@mui/material";
import SignUp from "./auth/SignUp";
import SignIn from "./auth/SignIn";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import Confirmation from "./auth/Confirmation";
import PasswordConfirmation from "./auth/PasswordConfirmation";
import ConfirmRedirect from "./auth/ConfirmRedirect";
import ChangePassword from "./auth/ChangePassword";
import SignOut from "./auth/SignOut";
import DrawerLeft, { Main } from "./components/drawer/Drawer";
import NewProject from "./mrv/measure/NewProject";
import ReportDetails from "./mrv/reports/ReportDetails";
import MRVReports from "./mrv/reports/Reports";
import { backend_url } from "./services/Constants";
import MRVVerifyDetails from "./mrv/verification/VerifyDetails";
import ProfilePage from "./profile/ProfilePage";
import HomePage from "./home/HomePage";
import { TrainingData } from "./mrv/measure/TrainingData";
import { MeasureCarbon } from "./mrv/measure/MeasureCarbon";
import ProjectPage from "./mrv/projects/ProjectPage";
import TeamMembersPage from "./mrv/members/TeamMembersPage";
import FAQPage from "./mrv/faqs/FAQPage";
import Footer from "./components/Footer";
import ReportPage from "./mrv/reports/ReportPage";
import AddMembers from "./mrv/measure/AddMembers";
// import AddProjectDataSets from "./mrv/measure/AddProjectDataSets";
import AddBusiness from "./mrv/measure/AddBusiness";
import ShareReport from "./mrv/reports/ShareReport";
import NotFound from "./components/NotFound";
import UpdateProject from "./mrv/measure/UpdateProject";

function App(props) {
  const { window } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const [user, setUser] = React.useState("");
  const token = localStorage.getItem("access_token");

  React.useEffect(() => {
    fetch(`${backend_url}/auth/user/me/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUser(data.user);
        data.code === "token_not_valid" && localStorage.clear();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const [open, setOpen] = React.useState(isMobile === true ? false : true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <BrowserRouter>
      {user !== undefined && token ? (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <DrawerLeft
            user={user}
            token={token}
            window={window}
            open={open}
            handleDrawerToggle={handleDrawerToggle}
          >
            <Main open={open}>
              <Routes>
                <Route
                  path="/"
                  element={<HomePage token={token} user={user} />}
                />
                <Route
                  path="/projects"
                  element={<ProjectPage token={token} user={user} />}
                />
                <Route
                  path="/reports"
                  element={<ReportPage token={token} user={user} />}
                />
                <Route
                  path="/team-members"
                  element={<TeamMembersPage token={token} user={user} />}
                />
                <Route
                  path="/measure"
                  element={<MeasureCarbon token={token} user={user} />}
                />
                <Route
                  path="/report/:id"
                  element={<ReportDetails token={token} user={user} />}
                />
                <Route
                  path="/report/share/:id"
                  element={<ShareReport token={token} user={user} />}
                />
                <Route
                  path="/training/1"
                  element={<TrainingData token={token} user={user} />}
                />
                <Route
                  path="/details"
                  element={<MRVReports token={token} user={user} />}
                />
                <Route
                  path="/verify"
                  element={<MRVVerifyDetails token={token} user={user} />}
                />
                <Route
                  path="/new-project"
                  element={<NewProject token={token} user={user} />}
                />
                <Route
                  path="/update-project"
                  element={<UpdateProject token={token} user={user} />}
                />
                <Route
                  path="/new-project/farmers"
                  element={<AddMembers token={token} user={user} />}
                />
                {user.is_superuser === true && (
                  <Route
                    path="/new-project/business"
                    element={<AddBusiness token={token} user={user} />}
                  />
                )}
                <Route
                  path="/report/members"
                  element={<AddMembers token={token} user={user} />}
                />
                {/* <Route
                  path="/new-project/data-sets"
                  element={<AddProjectDataSets token={token} user={user} />}
                /> */}
                <Route
                  path="/profile"
                  element={<ProfilePage token={token} user={user} />}
                />
                <Route
                  path="/faqs"
                  element={<FAQPage token={token} user={user} />}
                />
                <Route path="sign-out" element={<SignOut token={token} />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="*" element={<NotFound />} />{" "}
                {/* Add the 404 route */}
              </Routes>
              <Footer />
            </Main>
          </DrawerLeft>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/password_reset/" element={<ResetPassword />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route
              path="/password-confirmation"
              element={<PasswordConfirmation />}
            />
            <Route path="/auth/verify_email" element={<ConfirmRedirect />} />
            <Route
              path="/report/share/:id"
              element={<ShareReport token={token} user={user} />}
            />
            <Route path="/projects" element={<Navigate to="/" />} />
            <Route path="/reports" element={<Navigate to="/" />} />
            <Route path="/team-members" element={<Navigate to="/" />} />
            <Route path="/measure" element={<Navigate to="/" />} />
            <Route path="/report/:id" element={<Navigate to="/" />} />
            <Route path="/training/1" element={<Navigate to="/" />} />
            <Route path="/details" element={<Navigate to="/" />} />
            <Route path="/verify" element={<Navigate to="/" />} />
            <Route path="/new-project" element={<Navigate to="/" />} />
            <Route path="/profile" element={<Navigate to="/" />} />
            <Route path="/faqs" element={<Navigate to="/" />} />
            <Route path="/sign-out" element={<Navigate to="/" />} />
            <Route path="/change-password" element={<Navigate to="/" />} />
            <Route path="*" element={<NotFound />} /> {/* Add the 404 route */}
          </Routes>
        </ThemeProvider>
      )}
    </BrowserRouter>
  );
}

export default App;

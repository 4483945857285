import React, { useState } from "react";
import {
  Paper,
  Container,
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { lighten } from "@mui/material/styles";
import { backend_url } from "../../services/Constants";
import axios from "axios";
import MRVSnackbar from "../../components/MRVSnackbar";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import format from "date-fns/format";
import { useDropzone } from "react-dropzone";
import Loading from "../../components/Loading";
import { countries } from "./countries";
// import moment from "moment";

function NewProject(props) {
  const { token, user } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [verStatus, setVerStatus] = useState("Notstarted");
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    accept: ".geojson, .shp, .shx, .dbf",
    onDrop: (value) => {
      setUploadedFiles(value);
      // if (acceptedFiles.length > 0) {
      //   onChange(acceptedFiles[0]);
      // }
    },
  });

  const countrys = countries && countries.map((countries) => countries.name);

  const schema = yup.object().shape({
    spatial_upload:
      uploadedFiles && uploadedFiles.length > 0
        ? yup.mixed().notRequired()
        : yup.mixed().required("Please upload your project boundary"),
    project_title: yup.string().required("This field is required"),
    verification_status: yup.string().required("This field is required"),
    project_type: yup.string().required("This field is required"),
    description: yup.string().notRequired(),
    start_date: yup.string().notRequired(),
    end_date: yup.string().notRequired(),
    country: yup
      .string()
      .required("You must enter the country")
      .oneOf(countrys !== false ? countrys : []),
    State: yup.string().notRequired(),
    hectares: yup.number().required("You must enter date of program"),
  });

  const defaultValues = {
    spatial_upload: null,
    description: "",
    project_title: "",
    hectares: 0,
    verification_status: "",
    project_type: "",
    start_date: null,
    end_date: null,
    country: "",
    State: "",
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  function submit({
    hectares,
    description,
    verification_status,
    project_type,
    project_title,
    // additional_info,
    spatial_upload,
    start_date,
    end_date,
    country,
    State,
  }) {
    const formData = new FormData();

    if (spatial_upload) {
      spatial_upload.forEach((file) => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (fileExtension === "geojson") {
          formData.append("file", file);
        } else {
          formData.append("shp_file", file.shp);
          formData.append("shx_file", file.shx);
          formData.append("dbf_file", file.dbf);
        }
      });
    }

    if (uploadedFiles) {
      uploadedFiles.forEach((file) => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (fileExtension === "geojson") {
          formData.append("file", file);
        } else {
          formData.append("shp_file", file.shp);
          formData.append("shx_file", file.shx);
          formData.append("dbf_file", file.dbf);
        }
      });
    }

    formData.append("name", project_title);
    formData.append("hectares", hectares);
    formData.append("description", description);
    formData.append("status", verification_status);
    formData.append("type", project_type);
    formData.append("State", State);
    formData.append("country", country);
    formData.append("start_date", format(new Date(start_date), "yyyy-MM-dd"));
    formData.append("end_date", format(new Date(end_date), "yyyy-MM-dd"));

    axios
      .put(
        `${backend_url}/farm/project/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Project Created Successfully.");
        setLoading(false);
        window.location.href = user.is_superuser
          ? "/new-project/business"
          : "/new-project/farmers";
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.response.data.details);
        setLoading(false);
      });
  }

  return (
    <div style={{ marginBottom: "64px" }}>
      <div elevation={0}>
        <Container component="main" maxWidth="lg">
          <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 sm:pt-24 sm:pb-4">
            <div className="flex flex-auto items-center min-w-0">
              <div className="flex flex-col items-center min-w-0 px-2 sm:px-8 pt-32 sm:pb-32 pb-16">
                {/* <Typography
                  variant="p"
                  component="h5"
                  className="text-md md:text-lg text-center font-semibold  "
                >
                  Create a new project
                </Typography> */}
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Container component="main" maxWidth="lg">
        <Paper className="px-0 sm:px-16 py-4 mt-8" elevation={1}>
          <form
            name="tripForm"
            noValidate
            className="flex flex-col justify-center w-full px-8 mt-8"
            onSubmit={handleSubmit(submit)}
          >
            {openMessage === true && (
              <MRVSnackbar
                openMessage={openMessage}
                severity={severity}
                message={message}
              />
            )}

            <Typography
              component="h5"
              variant="p"
              className="py-8 text-sm md:text-md font-semibold sm:font-bold"
            >
              Project Details
            </Typography>

            <div>
              <Controller
                name="project_title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Project Name: "
                    id="project_title"
                    variant="outlined"
                    sx={{ pr: { sm: 0, lg: 4 }, mb: 1, mt: 1 }}
                    error={!!errors.project_title}
                    helperText={errors?.project_title?.message}
                    multiline
                    minRows={2}
                    fullWidth
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Description"
                    id="description"
                    variant="outlined"
                    sx={{ pr: { sm: 0, lg: 4 }, my: 2 }}
                    error={!!errors.description}
                    helperText={errors?.description?.message}
                    multiline
                    minRows={4}
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="sm:flex m-4">
              <div className="w-full">
                <Controller
                  name="verification_status"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      sx={{ pr: { sm: 0, lg: 2 }, mb: 1, mt: 1 }}
                      fullWidth
                      error={!!errors.verification_status}
                    >
                      <InputLabel id="verification_status">Status</InputLabel>
                      <Select
                        labelId="verification_status"
                        id="verification_status"
                        label="Implementation Status"
                        {...field}
                      >
                        <MenuItem
                          value={"Notstarted"}
                          onClick={() => {
                            setVerStatus("Notstarted");
                          }}
                        >
                          Not Started
                        </MenuItem>

                        <MenuItem
                          value={"Ongoing"}
                          onClick={() => {
                            setVerStatus("Ongoing");
                          }}
                        >
                          Ongoing
                        </MenuItem>

                        <MenuItem
                          value={"Completed"}
                          onClick={() => setVerStatus("Completed")}
                        >
                          Completed
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </div>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="w-full">
                  <Controller
                    name="start_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        sx={{ pr: { sm: 0, lg: 2 }, mb: 1, mt: 1 }}
                        clearable
                        slotProps={{
                          textField: {
                            id: "from-date",
                            label: "Start Date",
                            error: errors.start_date,
                            helperText: errors?.start_date?.message,
                            InputLabelProps: {
                              shrink: true,
                            },
                            fullWidth: true,
                            variant: "outlined",
                          },
                          actionBar: {
                            actions: ["clear", "today"],
                          },
                        }}
                      />
                    )}
                  />
                </div>

                <div className="w-full">
                  <Controller
                    name="end_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        sx={{ pr: { sm: 0, lg: 2 }, mb: 1, mt: 1 }}
                        clearable
                        slotProps={{
                          textField: {
                            id: "to-date",
                            label: "End Date",
                            error: errors.end_date,
                            helperText: errors?.end_date?.message,
                            InputLabelProps: {
                              shrink: true,
                            },
                            fullWidth: true,
                            variant: "outlined",
                          },
                          actionBar: {
                            actions: ["clear", "today"],
                          },
                        }}
                      />
                    )}
                  />

                  <span className="p-16"></span>
                </div>
              </LocalizationProvider>
            </div>

            <div className="sm:flex m-4">
              <Controller
                name="project_type"
                control={control}
                render={({ field }) => (
                  <FormControl
                    sx={{ pr: { sm: 0, lg: 4 }, mb: 1, mt: 1 }}
                    fullWidth
                    error={!!errors.project_type}
                  >
                    <InputLabel id="project_type">Project Type</InputLabel>
                    <Select
                      labelId="project_type"
                      id="project_type"
                      label="Status"
                      {...field}
                      fullWidth
                    >
                      <MenuItem
                        value={"Agroforestry"}
                        onClick={() => {
                          setVerStatus("Agroforestry");
                        }}
                      >
                        Agroforestry
                      </MenuItem>

                      <MenuItem
                        value={"Crop Sequestration"}
                        onClick={() => {
                          setVerStatus("Crop Sequestration");
                        }}
                      >
                        Crop Sequestration
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />

              {countries !== undefined && (
                <Controller
                  name="country"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      sx={{ pr: { sm: 0, lg: 4 }, mb: 1, mt: 1 }}
                      id="country"
                      fullWidth
                      options={countrys}
                      value={value}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          helperText={errors?.country?.message}
                          error={!!errors.country}
                          {...params}
                          label="Country"
                        />
                      )}
                    />
                  )}
                />
              )}
            </div>

            <div className="sm:flex m-4">
              <Controller
                name="State"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="State Region: "
                    id="State"
                    variant="outlined"
                    sx={{ pr: { sm: 0, lg: 4 }, mb: 1, mt: 1 }}
                    error={!!errors.State}
                    helperText={errors?.State?.message}
                    multiline
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="sm:flex mx-4 mt-8 font-semibold">
              <Typography variant="p" className="sm-flex">
                Project Area
              </Typography>
            </div>

            <div className="sm:flex sm:-mx-4 mt-8">
              <Controller
                control={control}
                name="spatial_upload"
                render={({ field: { onChange, value } }) => (
                  <Box
                    {...getRootProps()}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark"
                          ? lighten(theme.palette.background.default, 1)
                          : lighten(theme.palette.background.default, 0.02),
                    }}
                    component="label"
                    htmlFor="button_spatial_upload"
                    className="flex items-center justify-center relative w-full h-128 rounded-16 mx-2 sm:mx-12 mb-24 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                  >
                    <input
                      {...getInputProps()}
                      accept=".geojson, .shp, .shx, .dbf,"
                      className="hidden"
                      id="button_spatial_upload"
                      type="file"
                      onChange={(e) => onChange(Array.from(e.target.files))}
                    />
                    <IconButton>
                      <UploadFileIcon
                        sx={{ color: "action" }}
                        fontSize="large"
                      />
                    </IconButton>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="p"
                        component="p"
                        className="text-sm font-small pl-8 pr-16"
                      >
                        <span> Drag any geographic file or browse.</span>
                      </Typography>

                      <Typography
                        variant="caption"
                        component="p"
                        className="text-xs font-small pl-8 pr-16"
                      >
                        SHP, GeoJSON, or KMl files are allowed
                      </Typography>

                      <Typography
                        variant="p"
                        component="p"
                        className="text-sm font-small pl-8 py-8 pr-16"
                      >
                        {value &&
                          value.length > 0 &&
                          value.map((file, index) => (
                            <span key={index}>
                              {file.name} <br />
                            </span>
                          ))}
                        {uploadedFiles &&
                          uploadedFiles.map((file) => (
                            <span>
                              {file.name} <br />
                            </span>
                          ))}
                      </Typography>
                    </div>
                    <FormHelperText>
                      <span className="text-red-500">
                        {" "}
                        {errors?.spatial_upload?.message}
                      </span>
                    </FormHelperText>
                  </Box>
                )}
              />
            </div>

            <Button
              sx={{ mt: 4 }}
              variant="contained"
              color="success"
              // disabled={_.isEmpty(dirtyFields) || !isValid}
              onClick={() => {
                setOpenMessage(false);
                setLoading(true);
              }}
              type="submit"
            >
              {loading === true ? (
                <Loading />
              ) : user.is_superuser ? (
                "Proceed to add business"
              ) : (
                "Proceed to add farmers"
              )}
            </Button>
          </form>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
          </Box>
        </Paper>
      </Container>
    </div>
  );
}

export default NewProject;

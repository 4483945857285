import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import TotalCarbonStock from "./widgets/TotalCarbonStock";
import CropCarbonStock from "./widgets/CropCarbonStock";
import TreeCarbonStock from "./widgets/TreeCarbonStock";
import SoilCarbonStock from "./widgets/SoilCarbonStock";
import CarbonBarChart from "./widgets/CarbonBarChart";
import CropCarbonBarChart from "./widgets/CropCarbonBarChart";
import TreeCarbonBarChart from "./widgets/TreeCarbonBarChart";
import SoilCarbonTable from "./widgets/SoilCarbonTable";
import { backend_url } from "../../services/Constants";
import Loading from "../../components/Loading";
import { LeafLayers } from "./maps/LeafLayers";
import MRVSnackbar from "../../components/MRVSnackbar";

export default function ProjectDashboard(props) {
  const {
    data,
    wmsLink,
    training,
    showDescription,
    setShowDescription,
    project_data,
    project_title,
    token,
    id,
    project_description,
    classified_image_data,
    selectedChip,
    loading,
    setLoading,
    openMessage,
    setOpenMessage,
  } = props;

  const [cropCarbon, setCropCarbon] = useState(null);
  const [treeCarbon, setTreeCarbon] = useState(null);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const params = {
          project_id: id,
        };

        if (selectedChip !== "all") {
          params.season_id = selectedChip;
        }

        const urlParams = new URLSearchParams(params).toString();

        const response = await fetch(
          `${backend_url}/farm/crop_carbon/?${urlParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (data.details) {
          setCropCarbon(null);
          setOpenMessage(
            data.details === "provide a valid season id" ||
              data.details === "provide a valid project id"
              ? false
              : true
          );
          setSeverity("error");
          setMessage(
            data.details === "provide a valid season id"
              ? "Please select a season"
              : data.details
          );
        } else {
          setCropCarbon(data);
        }
      } catch (error) {
        console.error("Error fetching carbon data:", error);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [selectedChip]);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/tree_carbon/?` +
          new URLSearchParams({
            project_id: id,
            // season_id: selectedChip === null ? "None" : selectedChip,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setTreeCarbon(data.details ? null : data))
        .catch((error) => {
          console.error("Error fetching carbon data:", error);
          setOpenMessage(true);
          setSeverity("error");
          setMessage(error.response.data.details);
          setLoading(false);
        });
    }

    fetchData();
  }, [token, selectedChip]);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <div className="flex flex-col items-center justify-between w-full min-w-0 pt-8 bg-gray-100 p-6">
      {openMessage === true && (
        <MRVSnackbar
          openMessage={openMessage}
          severity={severity}
          message={message}
        />
      )}

      <div className="grid grid-cols-12 w-full min-w-0">
        <motion.div
          className="col-span-12 sm:col-span-3"
          variants={container}
          initial="hidden"
          animate="show"
        >
          <div className="grid grid-cols-12 gap-4">
            <motion.div
              variants={item}
              className="col-span-6 sm:col-span-12 mb-4"
            >
              {loading === true ? (
                <Loading />
              ) : (
                <TotalCarbonStock
                  cropCarbon={
                    cropCarbon !== null && cropCarbon !== undefined
                      ? cropCarbon
                      : 0
                  }
                  treeCarbon={
                    treeCarbon !== null && treeCarbon !== undefined
                      ? treeCarbon
                      : 0
                  }
                />
              )}
            </motion.div>
            <motion.div
              variants={item}
              className="col-span-6 sm:col-span-12 mb-4"
            >
              {loading === true ? (
                <Loading />
              ) : (
                <CropCarbonStock
                  cropCarbon={
                    cropCarbon !== null && cropCarbon !== undefined
                      ? cropCarbon
                      : 0
                  }
                />
              )}
            </motion.div>

            <motion.div
              variants={item}
              className="col-span-6 sm:col-span-12 mb-4"
            >
              <TreeCarbonStock
                treeCarbon={
                  treeCarbon !== null && treeCarbon !== undefined
                    ? treeCarbon
                    : 0
                }
              />
            </motion.div>

            <motion.div
              variants={item}
              className="col-span-6 sm:col-span-12 mb-4"
            >
              <SoilCarbonStock carbon={undefined} />
            </motion.div>
          </div>
        </motion.div>

        <motion.div
          className="col-span-12 sm:col-span-9"
          variants={container}
          initial="hidden"
          animate="show"
        >
          <Card className="sm:ml-8 sm:px-6 pb-8 mb-8">
            <CardHeader
              title={
                <div>
                  <Typography variant="h6" className="font-bold">
                    Map View
                  </Typography>
                </div>
              }
            />
            <LeafLayers
              token={token}
              id={id}
              project_title={project_title}
              project_description={project_description}
              project_data={project_data}
              showDescription={showDescription}
              setShowDescription={setShowDescription}
              training={training}
              wmsLink={wmsLink}
              data={data}
              classified_image_data={classified_image_data}
            />
          </Card>
        </motion.div>
      </div>

      <div className="grid grid-cols-12 w-full min-w-0">
        <motion.div
          className="col-span-12 sm:col-span-6"
          variants={container}
          initial="hidden"
          animate="show"
        >
          {loading === true ? (
            <Loading />
          ) : (
            <CarbonBarChart
              data={undefined}
              cropCarbon={
                cropCarbon !== null && cropCarbon !== undefined ? cropCarbon : 0
              }
              treeCarbon={
                treeCarbon !== null && treeCarbon !== undefined ? treeCarbon : 0
              }
            />
          )}
        </motion.div>

        <motion.div
          className="col-span-12 sm:col-span-6"
          variants={container}
          initial="hidden"
          animate="show"
        >
          {loading === true ? (
            <Loading />
          ) : cropCarbon !== null && cropCarbon !== undefined ? (
            <CropCarbonBarChart
              selectedChip={selectedChip}
              cropCarbon={cropCarbon}
            />
          ) : (
            <Card className="flex flex-col flex-auto min-h-256 p-24 mt-2 shadow rounded-2xl overflow-hidden my-4 ml-2">
              <CardContent>
                <Typography className="" variant="h6">
                  Crop carbon content
                </Typography>
                <Typography className="" variant="p">
                  No data available
                </Typography>
              </CardContent>
            </Card>
          )}
        </motion.div>

        <motion.div
          className="col-span-12 sm:col-span-6"
          variants={container}
          initial="hidden"
          animate="show"
        >
          {treeCarbon !== null &&
          treeCarbon !== undefined &&
          treeCarbon.length > 0 ? (
            <TreeCarbonBarChart treeCarbon={treeCarbon} />
          ) : (
            <Card className="flex flex-col flex-auto min-h-256 p-24 mt-2 shadow rounded-2xl overflow-hidden my-4 ml-2">
              <CardContent>
                <Typography className="" variant="h6">
                  Tree carbon content
                </Typography>
                <Typography className="" variant="p">
                  No data available
                </Typography>
              </CardContent>
            </Card>
          )}
        </motion.div>

        <motion.div
          className="col-span-12 sm:col-span-6"
          variants={container}
          initial="hidden"
          animate="show"
        >
          <SoilCarbonTable data={undefined} />
        </motion.div>
      </div>
    </div>
  );
}

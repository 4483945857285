import * as React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { backend_url, zowasel_url } from "../../services/Constants";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
} from "@mui/material";
import axios from "axios";
import Loading from "../../components/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import MRVSnackbar from "../../components/MRVSnackbar";
import SearchIcon from "@mui/icons-material/Search";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "telephone",
    numeric: true,
    disablePadding: false,
    label: "Telephone",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "country",
    numeric: true,
    disablePadding: false,
    label: "Country",
  },
  {
    id: "state",
    numeric: true,
    disablePadding: false,
    label: "State",
  },

  {
    id: "gender",
    numeric: true,
    disablePadding: false,
    label: "Gender",
  },
  {
    id: "business",
    numeric: true,
    disablePadding: false,
    label: "Business Name",
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    onSelectAllClick,
    numSelected,
    rowCount,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: "#29333F" }}>
      <TableRow className="flex rounded-full">
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>

        <TableCell
          sx={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
            fontWeight: "bold",
            fontSize: "15px",
            color: "#fff",
          }}
        >
          S/N
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: "bold", fontSize: "15px", color: "#fff" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: "#fff !important" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function AddMembers(props) {
  const { token } = props;
  const [farmers, setFarmers] = useState();
  const [searchName, setSearchName] = useState("");
  const [projects, setProjects] = useState();
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(14);
  const [limit, setLimit] = useState(20);

  const query = useQuery();
  const id = query.get("id");
  const member_role = query.get("role");

  const farmer_data =
    farmers && farmers.data
      ? limit === 20
        ? farmers.data
        : farmers.data.slice(limit / (page + 1), limit)
      : undefined;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let url = `${zowasel_url}/controls/farmers/allfarmers/${offset}/${limit}`;

        if (searchName) {
          url = `${zowasel_url}/controls/farmer/getbyname/${searchName}`;
        }

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setFarmers(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchName, token, offset, limit]);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/project/?` +
          new URLSearchParams({
            mrv_p: 1,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setProjects(data));
    }

    fetchData();
  }, [token]);

  const proj_id =
    projects !== undefined &&
    projects.results !== undefined &&
    projects.results.features.map((n) => n.id)[0];

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("size");
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = farmer_data.map((n) => ({
        _id: n.farmers._id,
        telephone_number: n.farmers.telephone1,
        role: member_role !== null ? "Sample" : "Farmer",
      }));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id, telephone_number) => {
    const selectedIndex = selected.findIndex((item) => item._id === id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, {
        _id: id,
        telephone_number: telephone_number,
        role: member_role !== null ? "Sample" : "Farmer",
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setLimit(limit * (newPage + 1));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    setOffset(14);
  };

  const isSelected = (id) => selected.some((item) => item._id === id);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - farmer_data.length) : 0;

  // const visibleRows = useMemo(
  //   () =>
  //     stableSort(members_data, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  const defaultValues = {
    role: "",
  };

  const { handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
  });

  function onSubmit() {
    const farmMembers = {
      role_id: selected,
      telephone_number: selected,
      project: id !== null ? id : proj_id,
      role: member_role !== null ? "Sample" : "Farmer",
    };

    axios
      .put(
        `${backend_url}/farm/members/`,
        farmMembers,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Successfully added new member.");
        setLoading(false);
        navigate(`/report/${id !== null ? id : proj_id}?data=True`);
        // window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.response.data.details);
        setLoading(false);
      });
  }

  return (
    <Paper
      elevation={0}
      className={
        "flex flex-col flex-auto w-full p-12 mt-64 shadow rounded-2xl overflow-hidden mt-0"
      }
    >
      <div className="flex flex-col sm:flex-row items-start justify-between pb-12">
        <Typography variant="p" className="text-md text-center font-semibold  ">
          {member_role !== null
            ? "Select  sample taker to add to project"
            : "Select  farmer to add to project"}
        </Typography>

        {openMessage === true && (
          <MRVSnackbar
            openMessage={openMessage}
            severity={severity}
            message={message}
          />
        )}

        <Button
          className="whitespace-nowrap"
          variant="contained"
          color="primary"
          onClick={() => setConfirm(true)}
          size="large"
        >
          Next
        </Button>

        {confirm === true && (
          <Dialog onClose={() => setConfirm(false)} open={confirm}>
            <DialogTitle>
              <Typography
                variant="p"
                className="text-md text-center font-semibold  "
              >
                Accept Farm Practice Guide
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant="p" className="text-center  ">
                Do you agree to adopt farm practices suggested by ZOWASEL for
                your farm lands?
              </Typography>
            </DialogContent>

            <DialogActions>
              <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                <div className="flex justify-center w-full">
                  <Button
                    className="whitespace-nowrap"
                    variant="outlined"
                    color="error"
                    onClick={() => setConfirm(false)}
                  >
                    I REJECT
                  </Button>
                  <div className="px-8" />

                  <Button
                    className="whitespace-nowrap"
                    variant="contained"
                    color="success"
                    type="submit"
                  >
                    {loading === true ? <Loading /> : "I ACCEPT"}
                  </Button>
                </div>
              </form>
            </DialogActions>
          </Dialog>
        )}
      </div>

      <div className="flex flex-col pb-12">
        <Paper className="flex items-center  space-x-8 px-16 border-1 shadow-0 mb-12 py-4">
          <Input
            placeholder="Search by name"
            className="flex flex-1"
            disableUnderline
            fullWidth
            value={searchName}
            inputProps={{
              "aria-label": "Search by name",
            }}
            onChange={(e) => setSearchName(e.target.value)}
          />
          <IconButton>
            <SearchIcon color="primary" />
          </IconButton>
        </Paper>
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          {loading && <Loading />}
          {farmer_data !== undefined ? (
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={farmer_data.length}
            />
          ) : (
            <Loading />
          )}
          <TableBody>
            {farmer_data !== undefined &&
              farmer_data.map((farmer, index) => {
                const isItemSelected = isSelected(farmer.farmers._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    onClick={(event) => handleClick(event, farmer.farmers._id, farmer.farmers.telephone1)}
                    aria-checked={isItemSelected}
                    key={index}
                    sx={{
                      py: 2,
                      cursor: "pointer",
                      // "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                      }}
                    >
                      {(index += 1)}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                      }}
                      component="th"
                      scope="row"
                      padding="none"
                    >
                      {farmer.farmers !== null &&
                      farmer.farmers !== undefined &&
                      farmer.farmers.firstname === undefined
                        ? `${farmer.farmers.fullName}`
                        : `${
                            farmer.farmers !== null &&
                            farmer.farmers !== undefined &&
                            farmer.farmers.firstname
                          } ${
                            farmer.farmers !== null &&
                            farmer.farmers !== undefined &&
                            farmer.farmers.lastname
                          }`}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                      }}
                      align="right"
                    >
                      {farmer.farmers !== null &&
                        farmer.farmers !== undefined &&
                        farmer.farmers.telephone1}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                      }}
                      align="right"
                    >
                      {farmer.farmers !== null &&
                        farmer.farmers !== undefined &&
                        farmer.farmers.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                      }}
                      align="right"
                    >
                      {farmer.farmers !== null &&
                        farmer.farmers !== undefined &&
                        farmer.farmers.country}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                      }}
                      align="right"
                    >
                      {farmer.farmers !== null &&
                        farmer.farmers !== undefined &&
                        farmer.farmers.state}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                      }}
                      align="right"
                    >
                      {farmer.farmers !== null &&
                        farmer.farmers !== undefined &&
                        farmer.farmers.gender}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
                      }}
                      align="right"
                    >
                      {farmer.business !== null &&
                        farmer.business !== undefined &&
                        farmer.business.businessname}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell
                  sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.3)" }}
                  colSpan={6}
                />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={limit * 2 > 250 ? 250 : limit * 2}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ReportsWidget from "../../home/widgets/ReportsWidget";
import { backend_url } from "../../services/Constants";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Loading from "../../components/Loading";

function ProjectPage(props) {
  const { user, token } = props;
  const [projects, setProjects] = useState();
  const [hectaresRange, SetHectaresRange] = useState(0);
  const [hectaresRangeMax, SetHectaresRangeMax] = useState(99999999);
  const [verStatus, SetVerStatus] = useState("");
  const [searchValue, SetSearchValue] = useState("");
  const [apiSort, setAPISort] = useState("");
  const [offset, setOffset] = useState(0);
  const limit = 20;

  const project_data = projects && projects.results ? projects.results : [];

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${backend_url}/farm/project/?` +
          new URLSearchParams({
            offset: offset,
            limit: limit,
            name__icontains: searchValue,
            hectares__lt: hectaresRangeMax,
            hectares__gt: hectaresRange,
            status: verStatus,
            sort: apiSort,
          }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setProjects(data));
    }

    fetchData();
  }, [
    token,
    apiSort,
    hectaresRange,
    hectaresRangeMax,
    verStatus,
    searchValue,
    limit,
    offset,
  ]);

  return (
    user && (
      <div>
        <div className="w-full lg:ltr:pr-0 lg:rtl:pl-0 mt-72">
          <motion.div
            className="px-12 py-8"
            variants={container}
            initial="hidden"
            animate="show"
          >
            <Paper className="flex items-center  space-x-8 px-8 border-1 shadow-0 mb-12 py-4">
              <Input
                placeholder="Search project name"
                className="flex flex-1"
                disableUnderline
                fullWidth
                value={searchValue}
                inputProps={{
                  "aria-label": "Search Project Name",
                }}
                onChange={(e) => SetSearchValue(e.target.value)}
              />
              <IconButton>
                <SearchIcon color="primary" />
              </IconButton>
            </Paper>

            <div className="pb-12 w-full">
              <Accordion className="px-8 py-6 rounded-full border-1 shadow-0">
                <AccordionSummary
                  expandIcon={<ExpandCircleDownIcon color="primary" />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    variant="h6"
                    component={"p"}
                    className="font-bold"
                  >
                    Filter Projects By
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex flex-col sm:flex-row">
                    <TextField
                      sx={{ mr: { sm: 0, lg: 4 }, mt: 2 }}
                      id="hec-min"
                      fullWidth
                      label="Hectares Min"
                      placeholder="Hectares Min"
                      value={hectaresRange}
                      onChange={(e) => {
                        SetHectaresRange(e.target.value);
                      }}
                    />

                    <TextField
                      sx={{ mt: 2, mr: { sm: 0, lg: 4 } }}
                      id="hec-max"
                      fullWidth
                      label="Hectares Max"
                      placeholder="Hectares Max"
                      value={hectaresRangeMax}
                      onChange={(e) => {
                        SetHectaresRangeMax(e.target.value);
                      }}
                    />

                    <FormControl
                      sx={{ mt: 2, minWidth: "240px" }}
                      fullWidth
                    >
                      <InputLabel id="gender">Status</InputLabel>
                      <Select
                        labelId="verStatus"
                        id="verStatus"
                        label="Status"
                        variant="outlined"
                        value={verStatus}
                        onChange={(e) => SetVerStatus(e.target.value)}
                      >
                        <MenuItem value={""}>None</MenuItem>
                        <MenuItem value={"Notstarted"}>Not Started</MenuItem>
                        <MenuItem value={"Ongoing"}>Ongoing</MenuItem>
                        <MenuItem value={"Completed"}>Completed</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            {projects && projects.count === 0 && (
              <Typography
                variant="h6"
                component={"p"}
                className="text-center font-bold"
              >
                No projects found.
              </Typography>
            )}

            {projects && project_data.features !== undefined ? (
              project_data.features.length > 0 && (
                <motion.div
                  variants={item}
                  className="sm:col-span-2 md:col-span-4"
                >
                  <ReportsWidget
                    setAPISort={setAPISort}
                    apiSort={apiSort}
                    project_data={project_data}
                    count={projects.count}
                    token={token}
                    user={user}
                    setOffset={setOffset}
                  />
                </motion.div>
              )
            ) : (
              <Loading />
            )}
          </motion.div>
        </div>
      </div>
    )
  );
}

export default ProjectPage;

const protocol = window.location.protocol;
const hostname = window.location.hostname;

let share_url;
let backend_url;

if (window.location.hostname === "localhost") {
  share_url = "http://localhost:3000";
} else {
  share_url = `${protocol}//${hostname}`;
}

export { share_url };

if (window.location.hostname === "localhost") {
  backend_url = "http://localhost:8000";
} else {
  backend_url = "https://mrvbackend.zowasel.com";
}

export { backend_url };

export const zowasel_url = "https://globalstaging.zowasel.com";

export const googleMapsApiKey = "AIzaSyAwZQmP46s7b6CbhQAZL3JJtenykLOL4tY";

export const zowasel_token = "ZWSL_FARMER_4b3a_0242ac13";

import React, { useState } from "react";
import { Typography, IconButton, InputAdornment } from "@mui/material";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { backend_url } from "../services/Constants";
import MRVSnackbar from "../components/MRVSnackbar";
import Loading from "../components/Loading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const ChangePassword = (props) => {
  const { token, user } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const schema = yup.object().shape({
    old_password: yup.string().required("Please enter your old password."),
    password: yup
      .string()
      .required("Please enter your password.")
      .min(6, "Password is too short - must be at least 6 chars."),
    re_password: yup
      .string()
      .required("Please enter your password.")
      .min(6, "Password is too short - must be at least 6 chars."),
  });

  const defaultValues = {
    old_password: "",
    password: "",
    re_password: "",
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  function submit({ old_password, password, re_password }) {
    const formData = new FormData();
    formData.append("old_password", old_password);
    formData.append("new_password", password);
    formData.append("new_password2", re_password);

    axios
      .post(
        `${backend_url}/auth/user/change_password/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Password Changed Successfully");
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(err.response?.data?.details);
        setLoading(false);
      });
  }

  return (
    user.username !== undefined && (
      <React.Fragment>
        <Button
          className="whitespace-nowrap px-4"
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          size="medium"
          style={{ marginLeft: "8px", marginTop: "8px" }}
        >
          Change Password
        </Button>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>
            <Typography
              component="h5"
              variant="p"
              className="px-4 py-16 text-sm md:text-md font-semibold sm:font-bold"
            >
              Change Password
            </Typography>
          </DialogTitle>
          <DialogContent>
            <form
              name="profileForm"
              noValidate
              className="flex flex-col justify-center w-full mt-8 sm:mt-8  md:min-w-320"
              onSubmit={handleSubmit(submit)}
            >
              {openMessage === true && (
                <MRVSnackbar
                  openMessage={openMessage}
                  severity={severity}
                  message={message}
                />
              )}

              <div className="sm:flex my-4">
                <Controller
                  name="old_password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ m: 1 }}
                      id="old_password"
                      type={showPassword2 ? "text" : "old_password"}
                      label="Old Password"
                      error={!!errors.old_password}
                      helperText={errors?.old_password?.message}
                      variant="outlined"
                      required
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword2}
                              edge="end"
                            >
                              {showPassword2 ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>

              <div className="sm:flex my-4">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ m: 1 }}
                      id="password"
                      type={showPassword ? "text" : "password"}
                      label="New Password"
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      variant="outlined"
                      required
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>

              <div className="sm:flex my-4">
                <Controller
                  name="re_password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ m: 1 }}
                      type={showPassword1 ? "text" : "password"}
                      id="re_password"
                      label="Confirm New Password"
                      error={!!errors.re_password}
                      helperText={errors?.re_password?.message}
                      variant="outlined"
                      required
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword1}
                              edge="end"
                            >
                              {showPassword1 ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>

              <DialogActions>
                <Button
                  onClick={handleClose}
                  sx={{ mx: 1 }}
                  variant="contained"
                  color="error"
                  // disabled={_.isEmpty(dirtyFields) || !isValid}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ mx: 1 }}
                  variant="contained"
                  color="success"
                  // disabled={_.isEmpty(dirtyFields) || !isValid}
                  onClick={() => {
                    setOpenMessage(false);
                    setLoading(true);
                  }}
                  type="submit"
                >
                  {loading === true ? <Loading /> : "Submit"}
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  );
};
